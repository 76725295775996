import { useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import throttle from 'lodash/throttle'
import styled from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { zIndex } from 'bl-common/src/constants/zIndex'
import { Container } from 'bl-common/src/units/Container'
import { Subnavigation } from 'bl-common/src/units/Subnavigation/Subnavigation'

import { getSectionProps } from '../../../utils/getSectionProps'

export const FixedContainer = styled(motion.div)({
  backgroundColor: colors.white,
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: zIndex.menu,
  boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.1)',

  '.t-dark &': {
    backgroundColor: colors.darkmodeOffset,
  },
})

export const SubnavigationSection: React.FC = props => {
  const {
    items,
    scrollOffset = 0,
    alwaysVisible = false,
  } = getSectionProps(props)
  const [show, setShow] = useState(alwaysVisible)

  // Show subnavigation when user scrolls down
  useEffect(() => {
    if (alwaysVisible) {
      return
    }
    const handleScroll = throttle(() => {
      if (window.scrollY > window.innerHeight + scrollOffset) {
        setShow(true)
      } else {
        setShow(false)
      }
    }, 250)

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [alwaysVisible])

  return (
    <AnimatePresence>
      {show && (
        <FixedContainer
          initial={{ y: '-100%' }}
          animate={{ y: 0 }}
          exit={{ y: '-100%' }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
        >
          <Container>
            <Subnavigation>
              {items.map(item => (
                <Subnavigation.Item
                  key={item.sys.id}
                  name={item.fields.name}
                  anchorTag={item.fields.anchorTag}
                  link={item.fields.link?.fields.url}
                  isButton={item.fields.isButton}
                />
              ))}
            </Subnavigation>
          </Container>
        </FixedContainer>
      )}
    </AnimatePresence>
  )
}

export default SubnavigationSection
