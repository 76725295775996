import { BLOCKS } from '@contentful/rich-text-types'
import styled, { css } from 'styled-components'

import { GradientOffsetCard } from 'bl-common/src/cards/GradientOffsetCard/GradientOffsetCard'
import { colors } from 'bl-common/src/constants/colors'
import { Amount } from 'bl-common/src/elements/Amount'
import { Button } from 'bl-common/src/elements/Button/Button'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { ButtonLink } from 'bl-common/src/richText/RichTextBlocks'
import { RichTextRenderer } from 'bl-common/src/richText/RichTextRenderer'
import { Appear } from 'bl-common/src/units/Appear'
import { OffsetSection } from 'bl-common/src/units/Section/OffsetSection'
import { media } from 'bl-common/src/utils/media'
import { mixins } from 'bl-common/src/utils/mixins'

import { formatGradient } from '../../../utils/formatters'
import cardFormatters from '../../../utils/formatters/cards'
import { renderComponent } from '../../../utils/renderComponent'
import { StoryCard } from '../../Stories/components/StoryCard'

type ContentProps = {
  desktopDirection: string
}

type LeftProps = {
  ratio: string
}

type RightProps = {
  ratio: string
  columns: number
  twoColumnsMobile: boolean
  desktopDirection: string
}

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacing[5.5]};
  padding-bottom: ${({ theme }) => theme.spacing[5.5]};

  ${mixins.siteGutter()}

  ${({ desktopDirection, theme }) =>
    media.md(css`
      flex-direction: ${desktopDirection};
      justify-content: space-between;
      padding-top: ${theme.spacing[5.5]};
      padding-bottom: ${theme.spacing[5.5]};
    `)}
`

export const Left = styled.div<LeftProps>`
  flex: 0 0 ${({ ratio }) => ratio}%;
  padding-top: ${({ theme }) => theme.spacing[4]};
`

export const Right = styled.div<RightProps>`
  flex: 0 0 ${({ ratio }) => ratio}%;
  margin-top: ${({ theme }) => theme.spacing[4]};
  display: grid;
  grid-template-columns: 1fr;
  column-gap: ${({ theme }) => theme.spacing[2]};
  row-gap: ${({ theme }) => theme.spacing[2]};

  ${({ twoColumnsMobile }) =>
    twoColumnsMobile
      ? media.sm(css`
          grid-template-columns: 1fr 1fr;
        `)
      : media.smd(css`
          grid-template-columns: 1fr 1fr;
        `)}

  ${({ desktopDirection, theme, columns }) =>
    media.md(css`
      column-gap: ${({ theme }) =>
        columns > 2 ? theme.spacing[2] : theme.spacing[2.5]};
      row-gap: ${theme.spacing[4]};
      height: min-content;
      padding-left: ${desktopDirection === 'column' ? '0' : theme.spacing[6]};
      margin-top: 0;
    `)}

  ${({ columns }) =>
    columns < 3 &&
    media.md(css`
      grid-template-columns: repeat(${columns}, 1fr);
    `)}

  ${({ columns }) =>
    columns
      ? media.lg(css`
          grid-template-columns: repeat(${columns}, 1fr);
        `)
      : media.lg(css`
          grid-template-columns: 1fr 1fr;
        `)}
`

const documentOptions = {
  renderNode: {
    [BLOCKS.HEADING_2]: (_, children) => (
      <Type
        preset="headlineLarge"
        as="h2"
        bottom={{ xs: 1, md: 2 }}
        top={{ xs: 1, md: 1 }}
        maxWidth={400}
      >
        {children}
      </Type>
    ),
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Type preset="labelLarge" weight="normal" maxWidth={{ md: 340 }}>
        {children}
      </Type>
    ),
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      const { sys, fields } = node.data.target
      switch (sys.contentType.sys.id) {
        case 'ctaButton':
          return fields.displayAsLink ? (
            <ButtonLink
              textColor={fields.textColor}
              to={fields.link}
              emailTrigger={fields.emailTrigger}
              weight="bold"
              paddingSize="small"
            >
              {fields.text}
            </ButtonLink>
          ) : (
            <Button
              paddingSize={fields.paddingSize}
              preset={fields.color}
              textColor={fields.textColor}
              to={fields.link}
              maxWidth={373}
              top={{ xs: 1.5, md: 2.5 }}
              emailTrigger={fields.emailTrigger}
            >
              {fields.text}
            </Button>
          )
        case 'prices':
          return (
            <Type
              preset="text"
              as="p"
              lineHeight={2}
              weight="bold"
              color={colors.deepBlue}
              top={{ xs: 1 }}
            >
              <Amount
                value={fields.isk}
                format={fields.priceFormat}
                useSymbol
              />
            </Type>
          )
        default:
          return null
      }
    },
  },
}

const formatters = {
  gradientOffsetCard: cardFormatters.gradientOffsetCard,
  pageStory: item =>
    cardFormatters.pageStory({ ...item, imageRatio: 1.3, withDate: false }),
}

const cardTypes = {
  gradientOffsetCard: GradientOffsetCard,
  pageStory: StoryCard,
}

const CardGridSection = ({
  section: {
    fields: {
      items,
      textContent,
      backgroundGradient,
      textColor,
      offsetDirection,
      desktopDirection = 'row',
      columnCount = 3,
      columnCountMobile = 1,
      contentRatio = '30:60',
      config,
    },
  },
}: any) => {
  const formattedGradient = formatGradient(backgroundGradient)
  const color = textColor === 'light' ? colors.light : colors.dark
  const ratio = contentRatio.split(':')

  const contentType =
    items && items.length > 0 ? items[0].sys.contentType.sys.id : ''
  const Component = cardTypes[contentType]
  const formatter = formatters[contentType]
  const isStoryCard = contentType === 'pageStory'
  const twoColumnsMobile = columnCountMobile === 2 || isStoryCard

  return (
    <OffsetSection
      bottom={{ xs: 2, md: 6 }}
      top={{ xs: 2, md: 6 }}
      config={config}
      offsetDirection={offsetDirection}
      fullWidthMobile={true}
      background={formattedGradient}
      style={{ overflow: 'hidden' }}
    >
      <Appear observer>
        <Content desktopDirection={desktopDirection} style={{ color }}>
          <Left ratio={ratio[0]}>
            <Appear>
              {!!textContent && (
                <RichTextRenderer
                  document={textContent}
                  customOptions={documentOptions}
                />
              )}
            </Appear>
          </Left>
          <Right
            desktopDirection={desktopDirection}
            columns={columnCount}
            twoColumnsMobile={twoColumnsMobile}
            ratio={ratio[1]}
          >
            {renderComponent(items, { many: true, contentType }, item => {
              return (
                Component &&
                formatter && (
                  <Appear delay={100} key={item.sys.id}>
                    <Component {...formatter(item)} />
                  </Appear>
                )
              )
            })}
          </Right>
        </Content>
      </Appear>
    </OffsetSection>
  )
}

export default CardGridSection
